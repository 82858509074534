'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/tablets', {
        templateUrl: 'app/tablets/list.html',
        controller: 'TabletsController',
        authenticate: 'user',
        hasPermissions: ['TABLETS']
    }).when('/tablets/partners/id/:id', {
        templateUrl: 'app/tablets/list.html',
        controller: 'TabletsController',
        authenticate: 'user',
        hasPermissions: ['TABLETS:GET']
    }).when('/tablets/partners', {
        templateUrl: 'app/tablets/partners/list.html',
        controller: 'TabletPartnersController',
        authenticate: 'user',
        hasPermissions: ['TABLETS:GET']
    }).when('/tablets/add', {
        templateUrl: 'app/tablets/add.edit.tablet.html',
        controller: 'TabletsController',
        authenticate: 'user',
        hasPermissions: ['TABLETS:POST'],
        reloadOnSearch: false
    }).when('/tablets/:id', {
        templateUrl: 'app/tablets/item.html',
        controller: 'TabletsController',
        authenticate: 'user',
        hasPermissions: ['TABLETS:GET']
    }).when('/tablets/:id/edit', {
        templateUrl: 'app/tablets/add.edit.tablet.html',
        controller: 'TabletsController',
        authenticate: 'user',
        hasPermissions: ['TABLETS:POST']
    });
});
